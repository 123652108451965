\:root
  --ck-success-bgc: #2D8A4E
  --ck-success-color: #ffffff
  --ck-add-to-cart-bgc: #000000
  --ck-size-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.5' height='7.42'%3E%3Cpath d='M0,0H8L4,7Z' fill='white'/%3E%3C/svg%3E")
// Colors
$black: #000 !default
$black-bis:    hsl(0, 0%, 7%) !default
$black-ter:    hsl(0, 0%, 14%) !default

$white: #fff !default
$white-ter:    hsl(0, 0%, 96%) !default
$white-bis:    hsl(0, 0%, 98%) !default

$grey: #e6e6e6 !default
$grey-lightest: hsl(0, 0%, 93%) !default
$grey-lighter: hsl(0, 0%, 86%) !default
$grey-light:   hsl(0, 0%, 71%) !default
$grey-dark:    hsl(0, 0%, 29%) !default
$grey-darker:  hsl(0, 0%, 21%) !default

// TODO BETTER NAMING
$grey-light-new: hsl(0, 0%, 40%) !default
$grey-lighter-new: hsl(0, 0%, 60%) !default


$gold: #ded7cc !default
$gold-dark: #B09A5E
$alabaster: #f7f7f7 !default

$success: #3DCC6F
$success-light: #B8E6C8
$success-dark: var(--ck-success-bgc)

$danger: #e62e49
$error-light: #E6B8BE
$error-dark: #B32439

$warning: #E69539
$warning-light: #E6CBAC
$warning-dark: #B3742D


// Typography
$body-size: 14px !default

$family-sans-serif: "Roboto", Helvetica, Arial, sans-serif !default
$family-condensed-sans-serif: "Roboto Condensed", Impact, Helvetica, Arial, sans-serif !default
$family-icons: "ck-icons"

$size-1: 5rem !default
$size-2: 3rem !default
$size-3: 2.5rem !default
$size-4: 2rem !default
$size-5: 1.5rem !default
$size-6: 1.25rem !default
$size-7: 1rem !default
$size-8: 0.75rem !default

$size-9: 7rem !default

// Responsiveness
$small-mobile: 359px !default
$medium-mobile: 399px !default
$tablet: 768px !default
$laptop: 1279px !default

// Miscellaneous
$radius-small: 0 !default
$radius: 0 !default

$fullhd: 1440px !default
$fullhd-container: 1344px !default
