@import "~theme";
:global(div[data-component="Mobilemenu"]) {
  height: 100%;
}
.root {
  position: relative;
  overflow-x: hidden;
  height: 100%;
  button:focus-visible, a:focus-visible {
    outline: 2px solid $black;
    outline-offset: 2px;
  }
}
.scrollable {
  overflow-y: scroll;
  height: calc(100% - #{$navbar-height});
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tablist {
  display: flex;
  height: $navbar-height;
  background: $white;
}

.tab {
  font-size: 18px;
  line-height: 1;
  font-family: $family-secondary;
  font-weight: 700;
  text-transform: uppercase;
  color: $grey-light;
  padding: 18px 0;
  flex: 1 1 auto;
  border-radius: 0;
  border-bottom: 1px solid $grey-lighter;
  &:focus {
    color: $black;
  }
  &:not(:first-child) span {
    border-left: 1px solid $grey-lighter;
  }
  span {
    display: block;
  }
}

.activeTab {
  color: $black;
  border-bottom: 3px solid $black;
}

.tabpanel {
  display: none;
  margin-top: $spacing-unit * 2;
}

.activeTabpanel {
  display: block;
}

.itemList {
  padding: 0 $spacing-unit * 2;
  display: grid;
  row-gap: $spacing-unit * 2;
  grid-auto-flow: row;
}

.itemLink {
  position: relative;
  display: block;
  box-shadow: 0 3px 6px 0 rgba($color: #000000, $alpha: 0.08);
  padding-bottom: 31.96347%;
  > img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    object-position: center;
  }
  > button,
  > a {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    padding: 0 $spacing-unit * 2;
    color: inherit;
    align-items: center;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    width: 100%;
    text-align: left;
    font-family: $family-secondary;
  }
}

.submenuWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(245, 245, 245);
  z-index: 2;
  transform: translate3d(100%, 0, 0);
  transition: transform 300ms ease;
  display: none;
}

.brandsHeading {
  margin-bottom: $spacing-unit * 2;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: $spacing-unit * 2;
  column-gap: $spacing-unit * 2;
  margin-bottom: $spacing-unit * 2;
  padding: 0 $spacing-unit * 2;

  a:last-child {
    grid-column: span 2;
  }
}

.imageBlock {
  background-color: $white;
  display: block;
  position: relative;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  padding: $spacing-unit * 2 $spacing-unit * 6;
  img {
    display: block;
  }
}

.brand {
  composes: imageBlock;
  padding: $spacing-unit * 4.5 $spacing-unit * 3;
}

.center {
  margin: 0 auto;
}

.footer {
  display: block;
  background-color: $white;
  padding-bottom: 18px;
}

.footerItem {
  border-bottom: 1px solid $grey-lighter;
  ul {
    overflow-y: hidden;
    margin: 0;

    li {
      margin-left: 40px;
      margin-bottom: 9px;
      color: #4a4a4a;
    }
  }

  span:global(.icon):last-child:before {
    content: "\ed5d";
  }

  summary {
    list-style: none;
    &::-webkit-details-marker {
      display: none;
    }
  }

  details[open] {
    span:global(.icon):last-child:before {
      content: "\ed5e";
    }
  }

  > a, summary > div {
    display: flex;
    align-items: center;
    padding: $spacing-unit * 3 $spacing-unit * 2;
  }

  a, summary > div {
    color: inherit;
    font-size: 16px;

    span:not(:global(.icon)) {
      margin: 0 $spacing-unit * 2;
      flex-grow: 1;
      text-align: left;
    }
  }
}

.submenuHeading {
  composes: tablist;
  align-items: center;
  padding-left: $spacing-unit * 2;
  border-bottom: 1px solid $grey-lighter;
  button {
    width: 20px;
    margin-right: $spacing-unit * 2;
  }
}

.subheading {
  padding: $spacing-unit * 2.5 $spacing-unit * 2;
}

.submenuList {
  background-color: $white;
  margin: 0;
}

.submenuItem {
  border-bottom: 1px solid $grey-lighter;
  padding: $spacing-unit * 1.5 $spacing-unit * 2;

  &:first-child {
    border-top: 1px solid $grey-lighter;
  }

  a {
    display: flex;
    div {
      margin-right: $spacing-unit * 2;
      position: relative;
      width: 40px;
      div {
        width: 100%;
        padding-bottom: 100%;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 100%;
        }
      }
    }

    span:not(:last-child) {
      flex-grow: 1;
      display: flex;
      align-items: center;
      font-size: 16px;
    }
  }
}
