// Link colors

$link: $black
$link-visited: $black

// Table Override

$table-cell-padding: 1em

// Navbar Override

$navbar-background-color: $black
$navbar-height: 4rem //mobile header nav height, need to use rem instead of px
$desktop-navbar-height: 5rem
$navbar-item-color: $white
$navbar-item-hover-color: $white
$navbar-item-hover-background-color: transparent
$navbar-item-active-color: $white
$navbar-item-active-background-color: transparent
$navbar-item-img-max-height: unset

$navbar-dropdown-background-color: $white
$navbar-dropdown-border-top: none
$navbar-dropdown-offset: -4px
$navbar-dropdown-arrow: $link
$navbar-dropdown-radius: none
$navbar-dropdown-z: 37
$navbar-dropdown-boxed-radius: none
$navbar-dropdown-boxed-shadow: none
$navbar-dropdown-item-hover-color: $white
$navbar-dropdown-item-hover-background-color: $black
$navbar-dropdown-item-active-color: $white
$navbar-dropdown-item-active-background-color: $black
$navbar-divider-background-color: $border

// Modal Override

$modal-z: 20
$modal-background-background-color: rgba($black, 0.66)
$modal-content-width: 400px
$modal-content-margin-mobile: 20px
$modal-content-spacing-mobile: 160px
$modal-content-spacing-tablet: 40px
$modal-close-dimensions: 40px
$modal-close-right: 20px
$modal-close-top: 20px
$modal-card-spacing: 40px
$modal-card-head-background-color: $white
$modal-card-head-border-bottom: 1px solid transparent
$modal-card-head-padding: 20px
$modal-card-head-radius: 0
$modal-card-title-color: $text-strong
$modal-card-title-line-height: 1
$modal-card-title-size: $size-4
$modal-card-foot-radius: 0
$modal-card-foot-border-top: 1px solid transparent
$modal-card-body-background-color: $white
$modal-card-body-padding: 20px

// Pagination Override

$pagination-color: $grey-darker
$pagination-border-color: $grey-lighter
$pagination-margin: -0.25rem
$pagination-hover-color: $link-hover
$pagination-hover-border-color: $grey-darker
$pagination-focus-color: $link-focus
$pagination-focus-border-color: $grey-darker
$pagination-active-color: $link-active
$pagination-active-border-color: $link-active-border
$pagination-disabled-color: $grey
$pagination-disabled-background-color: $grey-lighter
$pagination-disabled-border-color: $grey-lighter
$pagination-current-color: $primary-invert
$pagination-current-background-color: $primary
$pagination-current-border-color: $primary
$pagination-ellipsis-color: $grey-light

// Notifiction Override

$notification-radius: $radius
$notification-padding: 1rem 2.5rem 1rem 1.5rem

// Card Override

$card-shadow: none

// Breadcrumb

$breadcrumb-item-color: $grey-light
$breadcrumb-item-hover-color: $grey-dark
$breadcrumb-item-active-color: $grey-light
$breadcrumb-item-separator-color: $grey-light

// Tabs
$tabs-border-bottom-color: transparent
$tabs-border-bottom-width: 2px
$tabs-link-hover-border-bottom-color: $black
$tabs-link-active-border-bottom-color: $black
$tabs-link-padding: 0.5rem 0.5rem

// Button
$button-border-width: 2px
$button-background-color: $black
$button-color: $white
$button-padding-vertical: 1rem
$button-padding-horizontal: 3rem

// Border
$default-border: 1px solid #E6E6E6

// Spacing
// Minimum half of the spacing-unit should be used: 0.5 * $spacing-unit
$spacing-unit: 6px
